/*
** Component to display header component (header)
** the OAuth handler library to redirect to IdP with correct URL parameters
*/

import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Configuration } from '../../app.constants';
import { HeaderSitecoreService } from '../shared/services/header-sitecore.service';
import { HeaderSitecoreTemplate } from '../shared/header-sitecore-template';
import { SubSink } from 'subsink';

import { MemberProfile } from '../../member-portal/shared/member-profile';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { INotifyChange, LanguageService } from '../shared/services/language.service';
import { ErrorService } from '../shared/services/error.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy{
  private subscriptions = new SubSink();
  headerContent: HeaderSitecoreTemplate = new HeaderSitecoreTemplate();
  message: string;
  home: string;
  memberProfile: MemberProfile = new MemberProfile();
  healthNetwork: string;
  lineOfBusiness: string;
  eligibility: string;
  doctor: string;
  LogoUrl: string;
  DefaultLogo: string;
  brandColor: string;
  public isSetZIndexHigh: boolean;
  public represenativeFirstName: string;
  public disableForPersonalRepresentative = false;
  
  

  // Constructor inject OAuth service handler and constants service
  constructor(
    private authService: OAuthService,
    public configuration: Configuration,
    private headerContentService: HeaderSitecoreService,
    private memberProfileService: MemberProfileService,
    private languageService: LanguageService,
    private errorService: ErrorService
) {
    this.authService.loginUrl = configuration.LoginUrl;
    this.authService.redirectUri = configuration.RedirectUri;
    this.authService.clientId = configuration.ClientId;
    this.authService.scope = configuration.Scope;
    this.authService.issuer = configuration.Issuer;
    this.authService.oidc = configuration.Oidc;
    this.authService.logoutUrl = configuration.LogoutUrl;
  }

  ngOnInit() {
    try {
      this.errorService.error = null;
      this.errorService.errorMessage = null;
      this.isSetZIndexHigh = false;
    
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$
      ])
      .subscribe(result => {
        this.memberProfile = this.memberProfileService.memberProfile;
        if (this.memberProfile) this.onLoad();
      });
    }
    catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private onLoad() {
    this.subscriptions.sink = this.headerContentService.getHeaderSitecoreContent()
        .subscribe(response => {
          this.headerContent = response;
        },
          error => {
            this.message = <any>error;
            this.LogoUrl = this.configuration.DefaultHeaderLogoPath;
          });

          this.updateUserInfo();
          this.setBrandColor();
          if (this.memberProfile.TCPAValue === 0) {
            this.isSetZIndexHigh = true;
          }

  }

  setBrandColor() {
    switch (this.memberProfile.LOB.LOBCode) {
      case this.configuration.MediCalLOBCode: {
        this.brandColor = this.configuration.BrandColorMedical;
        break;
      }
      case this.configuration.OneCareLOBCode:
      case this.configuration.OneCareConnectLOBCode: {
        this.brandColor = this.configuration.BrandColorOCC;
        break;
      }
      case this.configuration.PaceLOBCode: {
        this.brandColor = this.configuration.BrandColorPace;
        break;
      }
      default:
        this.brandColor = this.configuration.BrandColorMedical;
        break;
    }
  }

  public getEligibilityStatusLength(): number {
    let eligibilityLength = 10;
    if (this.memberProfile.LOB.LOBCode.length / 2 == 4) {
      eligibilityLength = 15;
    }
    else if (this.memberProfile.LOB.LOBCode.length / 2 > 4) {
      eligibilityLength = 16;
    }

    return eligibilityLength;
  }

  public updateUserInfo() {
    this.eligibility = this.memberProfile.EligibilityStatus;
    this.lineOfBusiness = this.memberProfile.LOB.LobDisplayText;
    if (this.memberProfile.PersonalRepresentative) {

      if (this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.configuration.PersonalRepresentativeTermed) {
        this.represenativeFirstName = this.memberProfile.PersonalRepresentative.FirstName;
      }

      this.disableForPersonalRepresentative =
        this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId === this.configuration.PersonalRepresentativePending
        || this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId === this.configuration.PersonalRepresentativeDenied;
    }
  }

  // Login via IdP
  public login() {
    try {
      this.authService.initImplicitFlow();
    }
    catch (e) {
      // WebApi log of error serviceLog.log(e.status + this.name);
      throw e;
    }
  }

  // Logout via IdP
  public logOut() {
    this.authService.revokeTokenAndLogout({
      client_id: this.authService.clientId,
      returnTo: this.authService.redirectUri
    }, true);
    sessionStorage.removeItem(this.configuration.SessionStateKey);
    }

  // Redirect to SecureAuth (IdP) Self Service realm
  public redirectToUserProfile() {
    window.location.href = this.configuration.UserProfileUrl;
  }

  // Use OAuth Handler to retrieve name from identity token
  public get name() {
    try {
      return this.memberProfile.FirstName + this.configuration.SpaceString + this.memberProfile.LastName;
    }
    catch (e) {
      return this.configuration.EmptyString;
    }
  }

  public get firstName() {
    try {
      return !this.represenativeFirstName ? this.memberProfile.FirstName : this.represenativeFirstName;
    }
    catch (e) {
      return this.configuration.EmptyString;
    }
  }

  // Use OAuth2 to check if access token has expired
  public get expired() {
    try {
      return !this.authService.hasValidAccessToken();
    }
    catch (e) {
      return true;
    }
  }


  @HostListener("window:scroll", [])
    onWindowScroll() {

      let scollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      let elem =  document.getElementById(this.configuration.HeaderNavbarId);
      if (scollPos > this.configuration.ScrollPositionOpacity) {
        elem.classList.add(this.configuration.HeaderNavbarSolidClass);
      } else {
        elem.classList.remove(this.configuration.HeaderNavbarSolidClass);
      }

    }


}
